export const REGULAR_TURN_DURATION = 2000
export const QUICK_TURN_DURATION = 1800
export const TIME_TRIAL_TURN_DURATION = 1000
export const TIME_TRIAL_INITIAL_TIME = 15000
export const POKEDEX_GLOW_COLOR_DEFAULT = 'rgb(0, 205, 255)'
export const POKEDEX_GLOW_COLOR_CORRECT = '#00FF00'
export const POKEDEX_GLOW_COLOR_INCORRECT = '#FF0000'
export const ROTOM_STYLE_CLASS_DEFAULT = ''
export const ROTOM_STYLE_CLASS_CORRECT = 'rotomResponseForCorrectAnswer'
export const ROTOM_STYLE_CLASS_INCORRECT = 'rotomResponseForWrongAnswer'
export const ROTOM_STYLE_CLASS_TTSTART = 'rotomResponseForTimeTrialStart'
export const ROTOM_STYLE_CLASS_TTEND = 'rotomResponseForTimeTrialFinished'
